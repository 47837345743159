import React from 'react';

import './styles/AboutUs_1.css';

import logo from '../images/tplus.svg';

class AboutUs_1 extends React.Component {

    render () {
        return (
            <div className={"about_us_1"}>
                <div className="about_us_1__header about_us_1__back">
                    <h1>¿Quienes somos?</h1>
                </div>
                <div className="about_us_1__content">
                    <div className="about_us_1__columns">
                        <p>
                            Somos una agencia de atracción de talento inspirada en romper el paradigma de como se realiza el reclutamiento en México.
                        </p>
                        <p>
                            A través de nuestros procesos de selección y profundo discernimiento descubrimos los valores esenciales de la gente creando un balance perfecto de aptitudes y talento para construir a tu equipo.
                        </p>
                    </div>
                </div>
                <div className={"about_us_1__we_are"}>
                    <h2>
                        Somos
                    </h2>
                    <img src={ logo } alt="TPlus" className="about_us_1__logo" />
                    <h5 className={"text-black"}>
                        Nuestro compromiso es que tu reclutamiento sea una experiencia
                    </h5>
                </div>
            </div>
        )
    }
}

export default AboutUs_1;