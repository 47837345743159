import React from 'react';
import logo from '../images/tplus.svg';
import { Link } from 'react-router-dom';

import './styles/Footer_1.css';


class Footer_1 extends React.Component {

    render () {
        return (
            <footer className="footer_1">
                <div className="footer_1__logo">
                    <Link to="/">
                        <img src={ logo } alt="TPlus" className="navbar_1__logo" />
                    </Link>
                </div>
                <div className="footer_1__address">
                    <div>Av. Bucareli 136</div>
                    <div>Colonia Centro , Cuauhtémoc</div>
                    <div>CP 06070</div>
                    <div>Teléfono: 55 31 22 59 68</div>
                    <div>jmar@tplus.com.mx</div>
                </div>
                <div className="footer_1__secciones">
                    <h2>Pruebas psicometricas</h2>
                    <div>
                        <Link to="/">
                            16 Factores de Personalidad
                        </Link>
                    </div>
                    <div>
                        <Link to="/">
                            Test Cleaver
                        </Link>
                    </div>
                </div>
                <div className="footer_1__secciones">
                    <h2>Pruebas psicometricas</h2>
                    <div>
                        <Link to="/">
                            16 Factores de Personalidad
                        </Link>
                    </div>
                    <div>
                        <Link to="/">
                            Test Cleaver
                        </Link>
                    </div>
                </div>
                <div className="footer_1__secciones">
                    <h2>Pruebas psicometricas</h2>
                    <div>
                        <Link to="/">
                            16 Factores de Personalidad
                        </Link>
                    </div>
                    <div>
                        <Link to="/">
                            Test Cleaver
                        </Link>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer_1;