import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope, faPhone, faAt, faMobileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './styles/Contact_1.css';

library.add(
    faEnvelope,
    faPhone,
    faMobileAlt,
    faAt
);

class Contact_1 extends React.Component {
    render () {
        return (
            
            <div className="contact_1">
                <div className="contact_1__header contact_1__back">
                    <h1>Contáctanos</h1>
                </div>
                    <div className="contact_1__header_ico">
                        <FontAwesomeIcon icon={['fas', 'envelope']} className="contact_1__envelope" />
                        <FontAwesomeIcon icon={['fas', 'phone']} className="contact_1__phone" />
                        <FontAwesomeIcon icon={['fas', 'at']} className="contact_1__at" />
                        <FontAwesomeIcon icon={['fas', 'mobile-alt']} className="contact_1__mobile" />
                    </div>
                <div className="contact_1__content">
                    <p>
                        Estaremos encantados de escucharte y responder a cualquier duda.
                        Envíanos un email o completa nuestro formulario de contacto y nosotros te llamamos.
                    </p>
                    <div className="contact_1__data_wrap">
                        <div className="contact_1__data">
                            <div className="contact_1__data_form">
                                <div>
                                    *Nombre
                                    <input type="text" />
                                </div>
                                <div>
                                    *Empresa
                                    <input type="text" />
                                </div>
                                <div>
                                    *Correo electrónico
                                    <input type="text" />
                                </div>
                                <div>
                                    *Teléfono
                                    <input type="text" />
                                </div>
                                <div>
                                    ¿En qué podemos ayudarte?
                                    <input type="text" />
                                </div>
                                <div className="contact_1__btn">
                                    <a className="btn">Enviar</a> 
                                </div>
                            </div>
                            <div className="contact_1__data_center">
                                <h4>
                                    CONTÁCTANOS
                                </h4>
                                <div>
                                    <h5>Correo electrónico</h5>
                                    jmar@tplus.com.mx
                                </div>
                                <div>
                                    <h5>Redes sociales</h5>
                                    <ul className="contact_1__social_network">
                                        <li>
                                            <a className={"inactive"}>
                                                <FontAwesomeIcon icon={['fab', 'twitter']} />
                                            </a>
                                        </li>
                                        <li>
                                            <a className="active" href="https://www.linkedin.com/company/tplus-m%C3%A9xico/">
                                                <FontAwesomeIcon icon={['fab', 'linkedin']} />
                                            </a>
                                        </li>
                                        <li>
                                            <a className={"inactive"}>
                                                <FontAwesomeIcon icon={['fab', 'youtube']} />
                                            </a>
                                        </li>
                                        <li>
                                            <a className={"inactive"}>
                                                <FontAwesomeIcon icon={['fab', 'facebook']} />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Contact_1;