import React from 'react';

import './styles/Navbar_1.css';
import logo from '../images/tplus.svg';
import { Link } from 'react-router-dom';

class Navbar_1 extends React.Component {
    render () {
        return (
            <div className="navbar_1">
                <div className="navbar_1__head">
                    <Link to="/">
                        <img src={ logo } alt="TPlus" className="navbar_1__logo" />
                    </Link>
                </div>
                <div className="navbar_1__wrap">
                    <div className="navbar_1__nav">
                        <div>
                            <ul>
                                <li className="navbar_1__sub">
                                    <a href="#">
                                        Servicios
                                    </a>
                                    <ul className="navbar_1__subitems">
                                        <li>
                                            <Link to='/atraccion-de-talento'>
                                                Atracción de talento
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to='/pruebas-psicometricas'>
                                                Pruebas psicométricas
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className="navbar_1__sub">
                                    <a href="#">
                                        Conócenos
                                    </a>
                                    <ul className="navbar_1__subitems">
                                        <li>
                                            <Link to='/quienes-somos'>
                                                Quiénes somos
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to='/mision-vision-valores'>
                                                Misión, visión y valores
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to='/por-que-tplus'>
                                                ¿Por qué TPlus?
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link to='/contacto'>
                                        Contacto
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Navbar_1;