import React from 'react';

import Navbar_2 from '../components/Navbar_2';
import Navbar_3 from '../components/Navbar_3';
import Footer from '../components/Footer';
import WhyWe_1 from '../components/WhyWe_1';

class PageWhyWe extends React.Component {
    render () {
        return (
            <div>
                <Navbar_2 />
                <Navbar_3 />
                <WhyWe_1 />
                <Footer />
            </div>
        )
    }
}

export default PageWhyWe;