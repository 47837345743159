import React from 'react';

import Footer from '../components/Footer';
import Contact_1 from '../components/Contact_1';
import Navbar_2 from '../components/Navbar_2';
import Navbar_3 from '../components/Navbar_3';

class PageAboutUs extends React.Component {
    render () {
        return (
            <div>
                <Navbar_2 />
                <Navbar_3 />
                <Contact_1 />
                <Footer />
            </div>
        )
    }
}

export default PageAboutUs;