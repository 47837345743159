import React from 'react';

import Footer from '../components/Footer';
import MisionVisionValues_1 from '../components/MisionVisionValues_1';
import Navbar_2 from '../components/Navbar_2';
import Navbar_3 from '../components/Navbar_3';

class PageMisionVisionValues extends React.Component {
    render () {
        return (
            <div>
                <Navbar_2 />
                <Navbar_3 />
                <MisionVisionValues_1 />
                <Footer />
            </div>
        )
    }
}

export default PageMisionVisionValues;