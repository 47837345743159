import React from 'react';
import { Link } from 'react-router-dom';

import './styles/Home_1.css';

class Home_1 extends React.Component {
    state = {
    };

    selectImage() {
        if (!this.state.indexImg) {
            this.setState({
                indexImg: Math.floor(Math.random() * 7) + 1
            })
        }

        return this.state.indexImg;
    }
    
    inView(element, fullHeightInView) {
        var e = document.querySelector(element);
        var docViewTop = window.scrollTop();
        var docViewBottom = docViewTop + window.height();
        var elemTop = e.offset().top;
        var elemBottom;
    
        if (fullHeightInView) {
            elemBottom = elemTop + e.height();
        } else {
            elemBottom = elemTop;
        }
        
        return ((elemBottom >= docViewTop) &&
            (elemTop <= docViewBottom) &&
            (elemBottom <= docViewBottom) &&
            (elemTop >= docViewTop));
    }

    render () {
        return (
            <div className={"home_1 home_1__back_" + this.selectImage()}>
                <div className="home_1_content">
                    <div>
                        <h1 className="text-white home_1_title">
                            Logramos la simbiosis exacta
                            <br />
                            el equipo perfecto
                        </h1>
                    </div>
                    <div className="home__form">
                        <Link to='/' className="btn">Conócenos</Link>
                        <Link to='/atraccion-de-talento' className="btn">Atracción de talento</Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default Home_1;
