import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faMagnet, faUsers, faAngleDoubleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './styles/TalentAttraction_1.css';

library.add(
    faMagnet,
    faUsers,
    faAngleDoubleLeft
);

class TalentAttraction_1 extends React.Component {

    render () {
        return (
            <div className="talent_1">
                <div className="talent_1__header talent_1__back">
                    <div className="talent_1__header_ico">
                        <div>
                            <FontAwesomeIcon icon={['fas', 'magnet']} transform={{ rotate: 90 }} />
                        </div>
                        <div>
                            <FontAwesomeIcon icon={['fas', 'angle-double-left']} size="xs" />
                            <FontAwesomeIcon icon={['fas', 'angle-double-left']} size="xs" />
                        </div>
                        <div>
                            <FontAwesomeIcon icon={['fas', 'users']} />
                        </div>
                    </div>
                    <h1>Atracción de talento</h1>
                </div>
                <div className="talent_1__content">
                    <p>
                        Identificaremos  talento que cubran tus necesidades, dando seguimiento al perfil con base a nuestro proceso el cual permitirá seleccionar al mejor candidato.
                    </p>
                    <ol className={"gradient-list"}>
                        <li>
                            Levantamos tu perfil y te ayudamos a identificar las necesidades del puesto.
                        </li>
                        <li>
                            Iniciamos la búsqueda de tu candidato publicándola en diversas fuentes de empleo y aplicamos hunting activo.
                        </li>
                        <li>
                            Aplicaremos evaluaciones psicométricas a todos los candidatos y así filtrar a los que cumplan tus necesidades.
                        </li>
                        <li>
                            Aplicaremos un segundo filtro de manera telefónica para conocer a tu candidato.
                        </li>
                        <li>
                            Realizaremos una entrevista presencial y/o videollamada con los candidatos viables a tu perfil.
                        </li>
                        <li>
                            Te proporcionaremos una terna para que realices la entrevista presencial y elijas al que mejor se adapte a ti
                        </li>
                        <li>
                            Te damos garantía de 3 meses y seguimiento después de la contratación del candidato.
                        </li>
                    </ol>
                </div>
            </div>
        )
    }
}

export default TalentAttraction_1;
