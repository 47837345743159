import React from 'react';

import './styles/WhyWe_1.css';

class WhyWe_1 extends React.Component {

    render () {
        return (
            <div className={"whywe_1"}>
                <div className={"whywe_1__header"}>
                    <h1>
                        ¿Por qué nosotros?
                    </h1>
                </div>
                <div className={"whywe_1__content"}>
                    <p>
                        Estamos tan convencidos de nuestro proceso de selección que si el candidato no dura 3 meses en tu empresa volvemos a generar el flujo sin costo alguno.
                    </p>
                    <p>
                        No iniciaremos el proceso de selección sin conocer perfectamente tus necesidades, para ello se te acompaña en todo momento.
                    </p>
                    <p>
                        Seguimiento a ti y al candidato durante 1 año.
                    </p>
                    <p>
                        Se evalúa por igual a todos los candidatos, eligiéndose al que mejor se adapte a ti.
                    </p>
                </div>
            </div>
        )
    }
}

export default WhyWe_1;