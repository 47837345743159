import React from 'react';
import { Link } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin} from "@fortawesome/free-brands-svg-icons";

import './styles/Navbar_3.css';
import logo from '../images/tplus.svg';

library.add(
    faLinkedin,
    faBars
);

class Navbar_3 extends React.Component {
    state = {
        showMenu: false
    };

    handleClickMenu = e => {
        this.setState({
            showMenu: !this.state.showMenu
        });
    };

    render () {
        return (
            <div className="navbar_3">
                <div>
                    <div className="navbar_3__head">
                        <Link to="/">
                            <img src={ logo } alt="TPlus" className="navbar_3__logo" />
                        </Link>
                    </div>
                    <div className={"navbar_3__button"+ (this.state.showMenu ? " navbar_3__button_clicked" : "")}>
                        <div>
                            <a type="button" onClick={this.handleClickMenu}>
                                <FontAwesomeIcon icon={['fas', 'bars']} />
                            </a>
                        </div>
                    </div>
                </div>
                <div className={"navbar_3__nav"+ (this.state.showMenu ? " show " : "")}>
                    <ul>
                        <li className="navbar_3__sub">
                            <a>
                                Servicios
                            </a>
                            <ul className="navbar_3__subitems">
                                <li>
                                    <Link to='/atraccion-de-talento'>
                                        Atracción de talento
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/pruebas-psicometricas'>
                                        Pruebas psicométricas
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li className="navbar_3__sub">
                            <a>
                                Conócenos
                            </a>
                            <ul className="navbar_3__subitems">
                                <li>
                                    <Link to='/quienes-somos'>
                                        Quiénes somos
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/mision-vision-valores'>
                                        Misión, visión y valores
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/por-que-tplus'>
                                        ¿Por qué TPlus?
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li className="navbar_3__without_subitems">
                            <Link to='/contacto'>
                                Contacto
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Navbar_3;