import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './styles/PsychometricTest_1.css';

library.add(
    faQuestion
);

class PsychometricTest_1 extends React.Component {

    render () {
        return (
            <div className="psychometric_test_1">
                <div className="psychometric_test_1__header psychometric_test_1__back">
                    <div>
                    <div className="psychometric_test_1__header_ico">
                        <FontAwesomeIcon icon={['fas', 'question']} />
                    </div>
                    </div>
                    <h1>
                        Pruebas psicométricas
                    </h1>
                </div>
                <div className="psychometric_test_1__content">
                    <p>
                        Contamos con nuestra propia plataforma para evaluar con exámenes psicométricos y  exámenes técnicos a nuestros candidatos.
                    </p>
                    <p>
                        También te damos la oportunidad de usar nuestro sistema para que tu área de Recursos Humanos se beneficie de nuestros procesos
                    </p>
                    <p>
                        <h5 className="psychometric_test_1_why">¿Por qué evaluar a tus colaboradores y candidatos?</h5>
                        Es importante conocer las competencias y así saber sus áreas de oportunidad para ejercer un mejor liderazgo a tu colaborador o nuevo colaborador.
                    </p>
                </div>
            </div>
        )
    }
}

export default PsychometricTest_1;
