import React from 'react';

import Footer_1 from '../components/Footer_1';
import Navbar_2 from '../components/Navbar_2';
import Navbar_3 from '../components/Navbar_3';
import Footer from '../components/Footer';
import TalentAttraction_1 from '../components/TalentAttraction_1';
import {
    LinkedinCompanyProfile,
    LinkedinFollowCompany,
    LinkedinLogin,
    LinkedinAddProfile,
    LinkedinProfile,
    LinkedinShare
  } from 'react-social-plugins';

class PageTalentAttraction extends React.Component {
    render () {
        return (
            <div>
                <Navbar_2 />
                <Navbar_3 />
                <TalentAttraction_1 />
                <LinkedinFollowCompany
                    companyId={76468740}
                    counter="top" // Or "right"
                    lang="es_MX"
                />
                <LinkedinShare
                    apiKey="xxxxxxxxxxxxxx"
                    authorize
                    lang="en_US"
                />
                <LinkedinProfile
  lang="en_US"
  profileUrl="https://www.linkedin.com/company/tplus-m%C3%A9xico/"
  format="inline" // Or "hover"
  text="TPlus México" // text to show in "hover" format
/>
                <Footer_1 />
                <Footer />
            </div>
        )
    }
}

export default PageTalentAttraction;