import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTwitter, faLinkedin, faYoutube, faFacebook } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './styles/Footer.css';

library.add(
    faTwitter,
    faLinkedin,
    faYoutube,
    faFacebook
);

class Footer extends React.Component {

    render () {
        return (
            <footer>
                <div className="footer__net">
                    <ul>
                        <li>
                            <a className={"inactive"}>
                                <FontAwesomeIcon icon={['fab', 'twitter']} />
                            </a>
                        </li>
                        <li>
                            <a className="active" href="https://www.linkedin.com/company/tplus-m%C3%A9xico/">
                                <FontAwesomeIcon icon={['fab', 'linkedin']} />
                            </a>
                        </li>
                        <li>
                            <a className={"inactive"}>
                                <FontAwesomeIcon icon={['fab', 'youtube']} />
                            </a>
                        </li>
                        <li>
                            <a className={"inactive"}>
                                <FontAwesomeIcon icon={['fab', 'facebook']} />
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="footer_rights">
                    Todos los derechos reservados TPlus © 2021
                </div>
            </footer>
        )
    }
}

export default Footer;