import React from 'react';

import Footer from '../components/Footer';
import Footer_1 from '../components/Footer_1';
import Home_1 from '../components/Home_1';
import Navbar_1 from '../components/Navbar_1';
import Navbar_3 from '../components/Navbar_3';

class PageHome extends React.Component {
    render () {
        return (
            <div>
                <Navbar_1 />
                <Navbar_3 />
                <Home_1 />
                <Footer_1 />
                <Footer />
            </div>
        )
    }
}

export default PageHome;