import React from 'react';

import Footer from '../components/Footer';
import Navbar_2 from '../components/Navbar_2';
import Navbar_3 from '../components/Navbar_3';
import PsychometricTest_1 from '../components/PsychometricTest_1';

class PagePsychometricTest extends React.Component {
    render () {
        return (
            <div>
                <Navbar_2 />
                <Navbar_3 />
                <PsychometricTest_1 />
                <Footer />
            </div>
        )
    }
}

export default PagePsychometricTest;