import React from 'react';
import { Link } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin} from "@fortawesome/free-brands-svg-icons";

import './styles/Navbar_2.css';
import logo from '../images/tplus.svg';

library.add(
    faLinkedin,
    faBars
);

class Navbar_2 extends React.Component {
    render () {
        return (
            <div className="navbar_2">
                <div className="navbar_2__head">
                    <Link to="/">
                        <img src={ logo } alt="TPlus" className="navbar_2__logo" />
                    </Link>
                </div>
                <div className="navbar_2__wrap">
                    <div className="navbar_2__nav">
                        <div>
                            <ul>
                                <li className="navbar_2__sub">
                                    <a>
                                        Servicios
                                    </a>
                                    <ul className="navbar_2__subitems">
                                        <li>
                                            <Link to='/atraccion-de-talento'>
                                                Atracción de talento
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to='/pruebas-psicometricas'>
                                                Pruebas psicométricas
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className="navbar_2__sub">
                                    <a>
                                        Conócenos
                                    </a>
                                    <ul className="navbar_2__subitems">
                                        <li>
                                            <Link to='/quienes-somos'>
                                                Quiénes somos
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to='/mision-vision-valores'>
                                                Misión, visión y valores
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to='/por-que-tplus'>
                                                ¿Por qué TPlus?
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link to='/contacto'>
                                        Contacto
                                    </Link>
                                </li>
                                <li className="navbar_2__social_network">
                                    <div>
                                        <a href="https://www.linkedin.com/company/tplus-m%C3%A9xico/">
                                            <FontAwesomeIcon icon={['fab', 'linkedin']} />
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Navbar_2;