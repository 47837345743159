import React from 'react';

import imagenMisionVisionValor from '../images/01-meta.jpg';
import './styles/MisionVisionValues_1.css';

class MisionVisionValues_1 extends React.Component {

    render () {
        return (
            <div className={"misionvisionvalores_1"}>
                <div className={"misionvisionvalores_1_header"}>
                </div>
                <div className={"misionvisionvalores_1__content"}>
                    <div>
                        <h3>
                            Misión
                        </h3>
                        <p>
                            Ubicar al candidato indicado en el trabajo ideal con profesionalismo y ética.
                        </p>
                    </div>
                    <div>
                        <h3>
                            Visión
                        </h3>
                        <p>
                            Lograr que nuestros clientes vean la atracción de talento como una experiencia que contribuye al crecimiento profesional del candidato y de su empresa.
                        </p>
                    </div>
                    <div>
                        <h3>
                            Valores
                        </h3>
                        <ul>
                            <li>
                                Laborar con <span className={"text-secondary"}>ética</span> y <span className={"text-secondary"}>profesionalismo</span>.
                            </li>
                            <li>
                                <span className={"text-secondary"}>Trato personalizado</span> hacia nuestros clientes y a nuestros candidatos.
                            </li>
                            <li>
                                <span className={"text-secondary"}>Interés genuino</span> por atraer al mejor talento.
                            </li>
                            <li>
                                <span className={"text-secondary"}>Innovación</span> en nuestros procesos.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default MisionVisionValues_1;