import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import PageHome from '../pages/PageHome';
import PagePsychometricTest from '../pages/PagePsychometricTest';
import PageTalentAttraction from '../pages/PageTalentAttraction';
import PageAboutUs from '../pages/PageAboutUs';
import PageMisionVisionValues from '../pages/PageMisionVisionValues';
import PageWhyWe from '../pages/PageWhyWe';
import PageContact from '../pages/PageContact';

function App() {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path='/' component={PageHome} />

                <Route exact path='/atraccion-de-talento' component={PageTalentAttraction} />
                <Route exact path='/pruebas-psicometricas' component={PagePsychometricTest} />

                <Route exact path='/quienes-somos' component={PageAboutUs} />
                <Route exact path='/mision-vision-valores' component={PageMisionVisionValues} />
                <Route exact path='/por-que-tplus' component={PageWhyWe} />
                
                <Route exact path='/contacto' component={PageContact} />
            </Switch>
        </BrowserRouter>
    );
}

export default App;